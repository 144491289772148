import { IonButton, IonContent, IonPage, IonIcon, IonCardContent, IonText, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import MlmHeader from '../components/MlmHeader';
import ShareTo from '../components/ShareTo';
import { getSharedTo, sharedTo, checkToken, deleteShare } from '../services/api';
import Loader from '../components/Loader';



interface Contact {
  id?: string,
  name?: string,
  val?: string,
  type?: string,

}


const Page: React.FC = () => {

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([] as Contact[])
  const [shareResult, setShareResult] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showMessage, setMessage] = useState<boolean>(false);
  const [showDeleteMessage, setDeleteMessage] = useState<boolean>(false);
  const [removeChildIndex, setRemoveChildIndex] = useState<number>(0);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  console.log('disableSubmit', disableSubmit)
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log('submited')
    setLoading(true);
    const domain = `${window.location.protocol}//${window.location.host}`;
    const items = formFields;
    // console.log(items);
    const data = {
      domain,
      items
    }
    let sharedToRes: any = await sharedTo(data);
    if (sharedToRes) {
      // console.log(sharedToRes);
      setShareResult(true);
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        const el = document.getElementById("success-animation");
        if (el !== null) {
          el.scrollIntoView();
        }
      }, 100);
    }

  }

  const addFields = (el: any) => {
    let object = {
      id: '',
      name: '',
      val: '',
      type: ''
    }
    if (formFields) {
      setFormFields([...formFields, object])
    }
    console.log(el)
    setTimeout(function () {


      el.target.scrollIntoView();
    }, 0);
  }

  const handleRemoveChild = (index: number) => {
    console.log('clicked', index);

    RemoveItem(formFields[index].val);
    const reducedArr = [...formFields];
    reducedArr.splice(index, 1);
    setFormFields(reducedArr);
  }

  const RemoveItem = async (value?: string) => {
    setLoading(true);
    if (value) {
      let removed: any = await deleteShare(value);
      console.log('removed', removed);

    }
    // if(removed && sharedTo.length > 0){
    //   setFormFields(sharedTo);
    //   setLoading(false);
    // }else{
    //   let object = {
    //     id:'',
    //     name: '',
    //     val: '',
    //     type:''
    //   }
    setLoading(false);
  }



  const fetchSharedTo = async () => {
    setLoading(true);
    let sharedTo: any = await getSharedTo();
    console.log('sharedTo', sharedTo);
    if (sharedTo && sharedTo.length > 0) {
      setFormFields(sharedTo);
      setLoading(false);
    } else {
      let object = {
        id: '',
        name: '',
        val: '',
        type: ''
      }
      setFormFields([object]);
      setLoading(false);
    }

  };

  useEffect(() => {
    let tokenIsValid = checkToken();
    if (!tokenIsValid) {
      console.log(tokenIsValid);
      history.push('/');
    } else {
      fetchSharedTo();
    }

  }, [])
  useEffect(() => {
    console.log('formFields', formFields);
    if (!Array.isArray(formFields) || !formFields.length) {
      console.log('isArray', !Array.isArray(formFields));
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }

  }, [formFields])


  return (
    <IonPage>
      <MlmHeader themecolor='black' position="relative" />

      <IonContent className='share_screen ion-padding'>
        <>

          <h1>Share your Life Map</h1>
          <p>By sharing you are giving your confidants access to an encrypted document - only they can read it. </p>
          <p>A message will prompt them to sign-up and get access to your Life Map</p>
          <p>Once you share your map with someone there is no need to reshare. When you edit your Map (in the future) it will automatically update on their end. </p>
          <p><strong>Who would you like to share it with?</strong></p>

          <form onSubmit={handleSubmit} className='shareForm'>

            {(formFields) && formFields.map((item, i) => (
              <ShareTo item={item} index={i} key={i} onClick={() => { setRemoveChildIndex(i); setDeleteMessage(true) }} disable={(item.val) ? true : false} />
            ))}

            <div className='shreButtonWrapper'>
              <IonButton className='white-button  no-padding plus-button' icon-only shape="round" onClick={(e) => { addFields(e); setSuccess(false) }}>
                <IonIcon icon="./assets/img/icon/plus.svg" />
              </IonButton>
              <Loader isloading={loading} />

              {(success) && (
                <p className='success'>
                  <IonIcon icon="./assets/img/icon/thumb.svg" />
                  <strong>&nbsp;Your sharing was successful!</strong>
                </p>
              )}

              {(!success) && (
                <IonButton slot="end" shape="round" fill="clear" disabled={disableSubmit} className=' black_button' onClick={() => { setMessage(true) }} >
                  Send <IonIcon slot="end" icon="./assets/img/icon/paperplaine.svg"></IonIcon>
                </IonButton>
              )}
            </div>
            <div className={(showMessage) ? 'sound-card-overlay review-card-overlay sound-card-overlay-active' : 'sound-card-overlay review-card-overlay'} id="messagesEndRef" >
              <div className='sound-card'>

                <IonCardContent>
                  <IonText>Please double check the emails you’ve entered before clicking on the share button.</IonText>
                </IonCardContent>

                <IonButton slot="start" fill="solid" shape="round" id="sendShare" className="black_button" type="submit" onClick={() => { setSuccess(false); setMessage(false) }} >
                  Share
                </IonButton>
                <IonButton fill="clear" slot="end" onClick={() => { setSuccess(false); setMessage(false) }}>Back to review</IonButton>
              </div>
            </div>
            <div className={(showDeleteMessage) ? 'sound-card-overlay review-card-overlay sound-card-overlay-active' : 'sound-card-overlay review-card-overlay'} id="messagesEndRef" >
              <div className='sound-card'>

                <IonCardContent>
                  <IonText>Are you sure you want to remove this person, he will no longer have acces to your Life Map. </IonText>
                </IonCardContent>

                <IonButton slot="start" fill="solid" shape="round" className="black_button" onClick={() => { handleRemoveChild(removeChildIndex); setDeleteMessage(false) }} >
                  Remove
                </IonButton>
                <IonButton fill="clear" slot="end" onClick={() => { setDeleteMessage(false) }}>Cancel</IonButton>
              </div>
            </div>
          </form>



          <div className="bottom-buttons review-bottom-buttons buttons_true nohpadding">
            <IonButton onClick={() => history.goBack()} className='white-button' shape="round" size="large" >Back </IonButton>
            {(shareResult) && (
              <IonButton slot="end" shape="round" size="large" fill="clear" className=' black_button' onClick={() => { history.push('/'); setShareResult(false) }} >
                Done
              </IonButton>
            )}

          </div>

        </>
      </IonContent>


    </IonPage>

  );
};

export default Page;
