import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonNote,
  IonModal,
  IonContent,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonTitle
} from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { locale, i18nConfig } from '../interfaces/Messages';

import { register, saveSecondaryEmail } from '../services/api';
import Loader from '../components/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SharedContact } from '../interfaces/User';

import { getGeolocation, sendOtp } from '../services/api';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator'
import { OverlayEventDetail } from '@ionic/core/components';

type Props = {
  contact?: SharedContact,
}
type secondaryEmail = {
  "secondaryEmail"?: string,
}
type registrationData = {

  "email"?: string,
  "secondaryEmail"?: string,
  "password"?: string,
  "key"?: string,
  "otpCode"?: string,
  "phone"?: string,
  "lastName"?: string,
  "firstName"?: string,
  "registrationCode"?: string

}
const Register: React.FC<Props> = ({ contact }): JSX.Element => {

  const [language, setLang] = useState(locale);
  const [tr] = useState(JSON.parse(i18nConfig.trans));
  const [loading, setLoading] = useState(false);

  const [loginResponse, setRes] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [phoneValue, setPhone] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState<boolean>();
  const [passwordMatch, setpasswordMatch] = useState<boolean>(true);
  const [emailMatch, setpemailMatch] = useState<boolean>(true);
  const [strongPassword, setpStrongPassword] = useState<boolean>(true);
  const [logged, setLogged] = useState<boolean>(false);
  const [notValidPhone, setNotValidPhone] = useState(true);
  const [userCountry, setuserCountry] = useState();
  const search = useLocation().search;
  const key = new URLSearchParams(search).get('key')!;
  const [display_registrationCode, setDisplay_registrationCode] = useState(true);


  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [secondaryEmail, SetSecondaryEmail] = useState<string>('');

  function confirm() {

    setLoading(true);
    saveSecondaryEmail(secondaryEmail)
      .then((response) => {

        if (response.data.uid) {

          modal.current?.dismiss(secondaryEmail, 'confirm');
          // window.location.href = "/";

        }
        if (response.data.error) {
          setError(response.data.error.message);
        }

      }).finally(() => {

        setLoading(false);
      });
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {

    if (ev.detail.role === 'cancel') {
      alert(`No worries, ${ev.detail.data}!`);
    }
    window.location.href = "/";
  }

  const history = useHistory();

  const validateEmail = (email: string) => {
    return validator.isEmail(email);

  };

  const validateStrongPassword = (password: string) => {
    if (validator.isStrongPassword(password, {
      minLength: 6, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 0
    })) {
      setpStrongPassword(true);
    } else {
      setpStrongPassword(false)
    }
  }

  const handlePhoneChange = (phone: any) => {
    // console.log(phone);
    if (phone) {
      setPhone(phone);
      let isValidPhone = isValidPhoneNumber(phone);
      console.log('isValidPhone', isValidPhone);
      setNotValidPhone(isValidPhone);
    }
  }
  const validatePassword = (password: string) => {
    var pass = (document.getElementById('password') as HTMLInputElement).value;

    if (pass !== password) {
      setpasswordMatch(false)
    } else {
      setpasswordMatch(true)
    }
  };
  const matchEmail = (email: string) => {
    var mail = (document.getElementById('email') as HTMLInputElement).value;

    if (mail !== email) {
      setpemailMatch(false)
    } else {
      setpemailMatch(true)
    }
  };

  const validateEmailEvent = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setEmailIsValid(undefined);
    if (value === '') return;
    setEmailValue(value);
    validateEmail(value) ? setEmailIsValid(true) : setEmailIsValid(false);

  };
  const validateSecondaryEmailEvent = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setEmailIsValid(undefined);
    if (value === '') return;

    const emailMatchmailIsValid = validateEmail(value);
    if (emailMatchmailIsValid) {
      SetSecondaryEmail(value);
      setEmailIsValid(true)
    } else {
      setEmailIsValid(false);
    }

  };

  const setExpirationToken = (expiration: string) => {
    localStorage.removeItem('expiration_token');
    let now = Math.floor(Date.now() / 1000);
    let endToken = now + parseFloat(expiration);
    console.log(now, endToken);
    localStorage.setItem('expiration_token', endToken.toString());
  }




  const handleSubmit = (data: any) => {
    setLoading(true);
    data.preventDefault();

    const rData: registrationData = {};
    if (data.target['email']) {
      rData.email = data.target['email'].value;
    }
    if (data.target['password']) {
      rData.password = data.target['password'].value;
    }
    if (data.target['userkey']) {
      rData.key = data.target['userkey'].value;
    }
    if (data.target['otpCode']) {
      rData.otpCode = data.target['otpCode'].value;
    }
    if (data.target['registrationCode']) {
      rData.registrationCode = data.target['registrationCode'].value;
    }
    if (phoneValue) {
      rData.phone = phoneValue;
    }
    rData.firstName = data.target['firstName'].value;
    rData.lastName = data.target['lastName'].value;
    // console.log(data)
    // console.log(rData)

    register(rData)
      .then((response) => {

        if (response.data.user_id) {
          //to login access token
          console.log(response.data.access_token);
          const res = JSON.stringify(response);
          //  console.log('register_res',res);
          localStorage.removeItem('user');
          localStorage.setItem('user', res);
          localStorage.setItem('isCreator', response.data.isCreator);

          localStorage.removeItem('access_token');
          localStorage.setItem('access_token', response.data.access_token);
          setExpirationToken(response.data.expires_in);
          //** for secondary email, currently remode feature */
          // if (response.data.organizationName) {
          //   setIsOpen(true);
          // } else {
          //   window.location.href = "/";
          // }
          window.location.href = "/";

        }
        if (response.data.error) {
          if (response.data.error.message === 'The password must be a string with at least 6 characters.') {
            setError('A password needs to have at least 6 characters, including a capital letter and a number.');
          } else if (response.data.error.message === 'TOO_SHORT') {
            setError('Your phone number is too short');
          } else if (response.data.error.message === 'The email address is already in use by another account.') {
            setError('There’s an error in your information');
          } else if (response.data.error.message === 'The email address is already in use by another account.') {
            setError('There’s an error in your information');
          } else {
            setError(response.data.error.message);
          }
        }
      }).finally(() => {
        if (contact) {
          history.push('shareWithMe?firstName=' + contact?.originalUserName + '&id=' + contact?.originalUserId);
        }

        setLoading(false);
      });
  }

  const resendOtp = async () => {

    const resendOtp: any = await sendOtp(key);
    console.log('resendOtp', resendOtp)
    setMessage(resendOtp.data.message);
  }

  useEffect(() => {
    setEmailIsValid(true);
  }, [contact]);
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setLogged(true);
    }
    getGeolocation()
      .then((response) => {

        if (response && response.YourFuckingCountry) {
          // console.log(response);
          setuserCountry(response.YourFuckingCountryCode);
        }
      })
  }, [logged]);

  return (

    <div>

      {(!logged) && (
        <>
          <h1 className='ion-text-center'>Register</h1>
          <form className="smallForm" onSubmit={handleSubmit}>
            <IonItem className="login_item">
              <IonLabel>First Name</IonLabel>
              <IonInput type="text" required id="firstName" name="firstName" clearOnEdit={false} />


            </IonItem>
            <IonItem className="login_item">
              <IonLabel>Last Name</IonLabel>
              <IonInput type="text" required id="lastName" name="lastName" clearOnEdit={false} />


            </IonItem>
            <IonItem className="login_item">
              <IonLabel>{tr.email}</IonLabel>

              <IonInput type="email" required name='email' id='email'
                disabled={contact?.sharedType === "email"}
                onIonInput={(event) => validateEmailEvent(event)}
                value={(contact?.sharedType === "email" ? contact?.value : emailValue)}
                autofocus

              ></IonInput>
              {(!emailIsValid) && (<IonNote slot="helper" color="warning">Invalid email</IonNote>)}

            </IonItem>
            <IonItem className="login_item">
              <IonLabel>Confirm Email</IonLabel>
              <IonInput type="email" required id="matchEmail" name="matchEmail" clearOnEdit={false} onIonChange={(event: any) => matchEmail(event.target.value)} />
              {(!emailMatch) && (<IonNote slot="helper" color="warning">Emails don't match</IonNote>)}

            </IonItem>
            <IonItem className="login_item">
              <IonLabel>{tr.password}</IonLabel>
              <IonInput type="text" required id="password" name="password" clearOnEdit={false} onIonChange={(event: any) => validateStrongPassword(event.target.value)} />
              {(!strongPassword) && (<IonNote slot="helper" color="warning">A password needs to have at least 6 characters, including a capital letter and a number. </IonNote>)}


            </IonItem>

            {(contact) && (
              <div>
                <IonItem className="login_item">
                  <IonLabel>One time code</IonLabel>
                  <IonInput type="text" required name='otpCode'
                    placeholder='otpCode'
                  ></IonInput>
                  <input type="hidden" name="userkey" id="userkey" value={key} />
                </IonItem>
                <p>Please enter the one time code you received in your Email.
                  <span onClick={resendOtp} className="resendOtp "> Send again</span></p>
                {(message) && (
                  <p >{message}</p>
                )}
              </div>

            )}
            <div>
            </div>

            <IonItem className="login_item register_phone ">

              <PhoneInput
                name="phoneField"
                id="phoneField"
                placeholder="Enter phone number"
                defaultCountry={userCountry}
                value={(contact?.sharedType === "phone" ? contact?.value : phoneValue)}
                onChange={(phone) => handlePhoneChange(phone)}

              />


              {(!notValidPhone) && (
                <IonNote slot="helper" color="warning">Please enter a valid phone number</IonNote>
              )}
            </IonItem>

            {(display_registrationCode) && (

              <IonItem className="login_item register_registrationCode" id="register_registrationCode">
                <IonLabel>{tr.registrationCode}</IonLabel>
                <IonInput type="text" name='registrationCode'></IonInput>
              </IonItem>
            )}
            {(!display_registrationCode) && (
              <IonNote color="warning" id="display_registrationCode" onClick={() => setDisplay_registrationCode(true)}><strong>{tr.codeDesc}</strong></IonNote>
            )}
            <p>
              <input type="checkbox" name="agreement" id="agreement" required />

              <IonLabel> I agree to the <Link to={{ pathname: '/terms-of-service' }}>Term of Service</Link> and <Link to={{ pathname: '/privacy-policy' }}>Privacy Policy</Link>, including  <Link to={{ pathname: '/cookie-policy' }}>Cookie Use</Link>.</IonLabel>
            </p>



            <IonButton expand="block" size="large" shape="round" className='yellow_button' id="RegisterBtn" type="submit" disabled={!emailMatch || !emailIsValid || !notValidPhone || !strongPassword}>Register</IonButton>
            {(error) && (
              <p className='errormessage smallForm error-box'>{error}</p>
            )}
          </form>


        </>
      )}
      {(logged) && (

        <p>You are already logged in </p>

      )}
      <IonModal id="example-modal" className="email-modal" ref={modal} isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)}>

        <IonContent className="ion-padding">

          <h2>Add a backup (personal) email address</h2>


          <form className="smallForm" >
            <Loader isloading={loading} />
            <IonItem className="contact_item">
              <IonLabel>Secondary Email: </IonLabel>
              <IonInput name='email'
                value={secondaryEmail}
                onIonInput={(event) => validateSecondaryEmailEvent(event)}
              ></IonInput>
              {(!emailIsValid) && (<IonNote slot="helper" color="danger">Invalid email</IonNote>)}
            </IonItem>
            {(secondaryEmail) && (
              <IonButton className='white-button ' shape="round" strong={true} disabled={loading} onClick={() => confirm()}>
                Add email
              </IonButton>
            )}

            <p><IonButton color="light" fill="clear" onClick={() => modal.current?.dismiss('You can always add it in your profile page', 'cancel')}>Not now  <IonNote> You can always add it in your profile page</IonNote></IonButton>
            </p>
            {(error) && (
              <p className='errormessage error-box'>  {error}</p>
            )}

          </form>


        </IonContent>


      </IonModal>
      <Loader isloading={loading} />

    </div>

  );
};

export default Register;
