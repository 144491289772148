import React, { useState, useEffect } from 'react';
import { locale, i18nConfig, tr } from '../interfaces/Messages';
import { resetPassword } from '../services/api';
import Loader from '../components/Loader';
import MlmHeader from '../components/MlmHeader';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonPage } from '@ionic/react';
import { Link, useLocation } from 'react-router-dom';


const Page: React.FC = (e) => {

  const [language, setLang] = useState(locale);
  const [tr] = useState(JSON.parse(i18nConfig.trans));
  const [passwordMatch, setpasswordMatch] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [isShown, setIsSHown] = useState(false);

  const search = useLocation().search;
  const codefromurl = new URLSearchParams(search).get('Code');

  const passwordSubmit = (data: any) => {
    setLoading(true);
    data.preventDefault();
    resetPassword(data)
      .then((response) => {
        console.log('response');
        console.log(response);

        if (response.data.error) {
          //todo display error message
          console.log(response.data.error.message);
          setError(response.data.error.message);
        } else {
          console.log('success')
          setSuccess(true)
        }


      }).finally(() => {
        setLoading(false);
      });

  }
  const validatePassword = (password: string) => {
    var pass = (document.getElementById('password') as HTMLInputElement).value;

    if (pass !== password) {
      setpasswordMatch(false)
    } else {
      setpasswordMatch(true)
    }
  };

  useEffect(() => {
    setSuccess(false)

  }, [codefromurl]);
  return (
    <IonPage>
      <MlmHeader themecolor="white" menu={false} />

      <IonContent className="ion-padding">
        <div className="video_wrapper home_video_wrapper">
          <video muted id='home_video' autoPlay loop playsInline preload='auto'>
            <source id="source_webm_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.webm' type="video/webm" />
            <source id="source_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.mp4' type="video/mp4" />
          </video>
        </div>
        <div className="welcome-wrapper">
          <div className="welcome_content">
            {(!codefromurl) && (<>
              <h1 className='ion-text-center'>Reset Password</h1>
              <p> Wrong Reset Password URL</p>
            </>
            )}
            {(codefromurl && !success) && (
              <>
                <h1 className='ion-text-center'>Reset Password</h1>
                <form className="smallForm" onSubmit={passwordSubmit}>


                  <IonItem className="login_item">
                    <IonLabel>{tr.password}</IonLabel>
                    <IonInput type={isShown ? "text" : "password"} required placeholder={tr.password} id="password" name="password" clearOnEdit={false} />
                    <IonIcon slot="end" className="passIcon" onClick={() => setIsSHown(!isShown)} icon={isShown ? "./assets/img/icon/eye.svg" : "./assets/img/icon/eye-outline.svg"}  ></IonIcon>

                  </IonItem>
                  <IonItem className="login_item">
                    <IonLabel>Confirm Password</IonLabel>
                    <IonInput type={isShown ? "text" : "password"} required id="matchPassword" name="matchPassword" clearOnEdit={false} onIonChange={(event: any) => validatePassword(event.target.value)} />
                    <IonIcon slot="end" className="passIcon" onClick={() => setIsSHown(!isShown)} icon={isShown ? "./assets/img/icon/eye.svg" : "./assets/img/icon/eye-outline.svg"}  ></IonIcon>
                    {(!passwordMatch) && (<IonNote slot="helper" color="warning">Password not match</IonNote>)}
                    <input type="hidden" name="codefromurl" id="codefromurl" value={codefromurl} />
                  </IonItem>
                  <IonButton expand="block" size="large" shape="round" className='yellow_button' type="submit" disabled={!passwordMatch}>Save new password</IonButton>

                  <p className="ion-text-center register_link">
                    <Link to={{ pathname: '/' }}>Back to login</Link>
                  </p>

                  {(error) && (
                    <p className="danger error-box">{error}</p>
                  )}
                </form>

              </>)}

            {(success) && (
              <p className="ion-text-center register_link">
                <IonIcon icon="./assets/img/icon/thumb.svg" />
                &nbsp;Your Password was changed!<br />
                Please <Link to={{ pathname: '/' }}>login</Link> to edit your Life Map
              </p>
            )}

            <Loader isloading={loading} />

          </div>
        </div>
      </IonContent>
    </IonPage>)
}

export default Page;