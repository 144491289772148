import { IonInput, IonContent, IonButton, IonPage, IonItem } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";


import MlmHeader from '../components/MlmHeader';
import Register from '../components/Register';
import Login from '../components/Login';
import { sendKey, checkToken } from '../services/api';
import Loader from '../components/Loader';

import { SharedContact } from '../interfaces/User';




const Shared: React.FC = () => {


  const [loading, setLoadin] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [contact, setContact] = useState<SharedContact>();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [authNeeded, setAuth] = useState(true);
  const [isOtpValid, setIsOtpValid] = useState();
  const search = useLocation().search;
  const key = new URLSearchParams(search).get('key');
  // 'wX/MgV8ajvnR9kvMk6P+D4nvQVaqoQF8yRYovuDQ6nZEsON/Boy3v5P6K5RGWd/A'

  const fetchContact = async (key: string) => {
    setLoadin(true);
    const k = encodeURIComponent(key);//srt('+','%2B',key);
    console.log('k', k)
    const contact: any = await sendKey(key);
    if (contact) {
      console.log(contact);

      if (contact.data.error) {
        setError(true);
        setErrorMessage(contact.data.error.message)

      } else {
        setContact(contact.data);
      }
    }

    setLoadin(false);

  };


  useEffect(() => {
    if (!key) {
      setError(true);
      setLoadin(false);
      setErrorMessage('No shared map found');
    } else {
      console.log('here')
      fetchContact(key);
    }
    let tokenIsValid = checkToken();
    if (tokenIsValid) {
      setAuth(false);
    }

  }, [])


  return (
    <IonPage>
      <div className="video_wrapper home_video_wrapper">
        <video muted id='home_video' autoPlay loop playsInline>
          <source id="source_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.mp4' type="video/mp4" />
          <source id="source_webm_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.webm' type="video/webm" />
        </video>
      </div>
      <MlmHeader themecolor='white' transparent={true} position="relative" />

      <IonContent className='ion-padding'>

        <div className="welcome-wrappe">
          <div className="welcome_content">

            {(error) && (

              <p className="ion-text-center error-box">{errorMessage}</p>
            )}


            {(contact && !contact.isUser && !error) && (

              <Register contact={contact} />
            )}
            {(contact && contact.isUser && authNeeded) && (
              <Login stateChanger={setAuth} contact={contact} />
            )}
            {(contact && contact.isUser && !authNeeded) && (
              <> <h1>Welcome to {contact?.originalUserName}'s Life Map</h1>
                <IonButton expand="block" size="large" shape="round" className='yellow_button' routerLink={`shareWithMe?firstName=${contact?.originalUserName}&id=${contact?.originalUserId}`} type="submit"> View {contact?.originalUserName}'s map</IonButton>
              </>
            )}

            <Loader isloading={loading} />

          </div>
        </div>
      </IonContent>


    </IonPage>

  );
};

export default Shared;
