import React, { useEffect, useState } from 'react';

import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Menu from './components/Menu';
import Home from './pages/Home';
import Onboard from './pages/Onboard';
import Share from './pages/Share';
import ShareWithMe from './pages/ShareWithMe';
import Review from './pages/Review';
import Profile from './pages/Profile';
import Questions from './pages/Questions';
import Register from './pages/Register';
import Logout from './pages/Logout';
import Shared from './pages/Shared';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import Contact from './pages/Contact';
import Confirm from './pages/Confirm';
import Upgrade from './pages/Upgrade';
import Forgot from './pages/Forgot';
import HomeScreen from './pages/HomeScreen';
import Error404 from './pages/Error404';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.scss';


setupIonicReact();
const tagManagerArgs = {
  gtmId: 'GTM-WHWP6Z3'
}

TagManager.initialize(tagManagerArgs)
const App: React.FC = () => {



  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" disabled={true} >
          <Menu />

          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/" exact={true} ><Home /></Route>
              <Route path="/onboard" exact={true} ><Onboard /></Route>
              <Route path="/questions" exact={true} render={(e) => <Questions />}></Route>
              <Route path="/share" exact={true} ><Share /></Route>
              <Route path="/shared" exact={true} ><Shared /></Route>
              <Route path="/review" exact={true} ><Review /></Route>
              <Route path="/shareWithMe" exact={true} ><ShareWithMe /></Route>
              <Route path="/profile" exact={true} ><Profile /></Route>
              <Route path="/register" exact={true} ><Register /></Route>
              <Route path="/homeScreen" exact={true} ><HomeScreen /></Route>
              <Route path="/logout" exact={true} ><Logout /></Route>
              <Route path="/privacy-policy" exact={true} ><Privacy /></Route>
              <Route path="/terms-of-service" exact={true} ><Terms /></Route>
              <Route path="/cookie-policy" exact={true} ><Cookies /></Route>
              <Route path="/contact" exact={true} ><Contact /></Route>
              <Route path="/confirm" exact={true} ><Confirm /></Route>
              <Route path="/upgrade" exact={true} ><Upgrade /></Route>
              <Route path="/forgot" exact={true} ><Forgot /></Route>
              <Route path="*" ><Error404 /></Route>
            </Switch>
          </IonRouterOutlet>


        </IonSplitPane>

      </IonReactRouter>
    </IonApp>
  );
};

export default App;
