import React from 'react';
import Upgrade from '../pages/Upgrade';
import { paidStatus } from '../interfaces/User';

type Props = {
  loading: boolean,
  authNeeded: boolean,
  paidStatus?: paidStatus,
}

const ConditionalUpgrade: React.FC<Props> = ({ loading, authNeeded, paidStatus }) => {

  const isExpired = paidStatus?.expirationDate ? new Date(paidStatus.expirationDate) < new Date() : false;
  const showUpgrade = !loading && !paidStatus?.paid && !authNeeded &&
    (!paidStatus?.isByRegstrationCode || (paidStatus?.isByRegstrationCode && isExpired));

  return showUpgrade ? <Upgrade /> : null;
};

export default ConditionalUpgrade;
