import { IonButton, IonContent, IonNote, IonPage } from '@ionic/react';
import MlmHeader from '../components/MlmHeader';
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { verifyChangeEmailToken } from '../services/api';
import Loader from '../components/Loader';

const Confirm: React.FC = () => {

  const [error, setError] = useState(false);
  const [error2, setError2] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);



  const search = useLocation().search;
  const checkForToken = () => {

    const change_email_token = new URLSearchParams(search).get('change_email_token');
    console.log(change_email_token);

    if (change_email_token) {
      verifyChangeEmailToken(change_email_token).then((response) => {

        if (response.data.id) {
          setMessage(true);

        }
        if (response.data.error) {

          setError(true);


        }
      }).finally(() => {


        setLoading(false);
      });
    } else {
      setError2('No token was provided');

    }


  };

  useEffect(() => {
    checkForToken();
  }, [])


  return (
    <IonPage>
      <MlmHeader themecolor='black' />
      <IonContent fullscreen className='main_content share_screen ion-padding'>
        <div className='container'>
          <h1>Confirm Email change</h1>

          <div >
            {(error2) && (

              <IonNote className="ion-text-center" color="danger">{error2}</IonNote>

            )}
            {(error) && (
              <>
                <h3 className="ion-text-center" >Email Change Failed</h3>
                <p>
                  Oops! Something went wrong, and we couldn't update your email address. Please try again later or contact our support team for assistance.
                </p>
              </>
            )}
            {(message) && (
              <>
                <h3 className="ion-text-center" >Email Change Successful!</h3>
                <p>
                  Your email address has been successfully updated. You can now continue using Matan's Life Map with your new email. If you encounter any issues, feel free to reach out to our support team.
                </p>
                <IonButton className=' white-button' expand="block" size="large" shape="round" routerLink="/" routerDirection="none" >Login</IonButton>
              </>
            )}
          </div>

          <Loader isloading={loading} />
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Confirm;


