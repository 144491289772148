import { FC, useState, useEffect, useRef } from 'react';

import { IonHeader, IonToolbar, IonButtons, IonButton, IonMenuButton, IonIcon } from '@ionic/react';
import AudioComp from '../components/audio';
import { useLocation } from 'react-router-dom';
import { audioService } from "../services/api"

type Props = {
  themecolor?: string,
  audiofile?: string,
  menu?: boolean,
  from?: string,
  authNeeded?: boolean,
  position?: string
  transparent?: boolean
}

const MlmHeader: FC<Props> = ({ themecolor, audiofile, menu, from, authNeeded, position, transparent }): JSX.Element => {
  const location = useLocation();
  const [tcolor, setColor] = useState("white")
  const [tr, settr] = useState('')

  let menustatus = true
  if (menu === false) { menustatus = false }

  if (location.pathname !== "/questions" && location.pathname !== "/onboard") {
    audioService.pause()
  }
  useEffect(() => {
    if (themecolor) {
      setColor(themecolor);
    }
  }, [themecolor])
  useEffect(() => {
    if (transparent) {
      settr('transparent');
    }
  }, [transparent])
  return (
    <IonHeader>
      <IonToolbar className={`header_${tcolor} ${position} ${tr}`}>
        {(location.pathname === "/questions" && menustatus) && (
          <AudioComp themecolor={tcolor} from={from} audiofile={audiofile}></AudioComp>
        )}


        <a href={(authNeeded) ? "https://matanslifemap.com/" : "/questions"} className='logo_link' aria-label="Link to main page"><IonIcon src="./assets/img/logoMLM.svg" className="logoMLM" ></IonIcon></a>
        {menustatus && (

          <IonButtons slot="end">
            <IonMenuButton><IonIcon icon="./assets/img/icon/menu.svg" size="large" ></IonIcon></IonMenuButton>
          </IonButtons>

        )}

      </IonToolbar>
    </IonHeader>
  )
};


export default MlmHeader;

